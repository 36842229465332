import { ReactNode } from 'react';
import { t } from 'i18next';

import { RuleComponent } from 'types';
import { RulesTab } from './RulesComponents/RulesTab/RulesTab';
import { RulesTabContext } from './RulesComponents/RulesTab/RulesTabContext';

export function CodeOfConduct(): ReactNode {
  const title = t('Components.Rules.CodeOfConduct.Title');
  const breadcrumbs = [
    { href: '/', title: t('Generic.Start') },
    { href: '/rules', title: t('Components.Rules.Title') },
  ];

  const items = [
    {
      name: t('Components.Rules.CodeOfConduct.Generic'),
      component: <RulesTab slug='generic' ruleType='CodeOfConduct' publicLoc />,
    },
    {
      name: t('Components.Rules.CodeOfConduct.Orga'),
      component: <RulesTab slug='orga' ruleType='CodeOfConduct' publicLoc />,
    },
    {
      name: t('Components.Rules.CodeOfConduct.Community'),
      component: (
        <RulesTab slug='community' ruleType='CodeOfConduct' publicLoc />
      ),
    },
    {
      name: t('Components.Rules.CodeOfConduct.Mediator'),
      component: (
        <RulesTab slug='mediator' ruleType='CodeOfConduct' publicLoc />
      ),
    },
  ];

  return (
    <RulesTabContext
      title={title}
      items={items as RuleComponent[]}
      breadcrumbs={breadcrumbs}
    />
  );
}
