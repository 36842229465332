import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, LinkText, TextField, ThestralCheckbox } from 'components/ui';

import { useFetchFromBackend, useSnackbar } from 'func';
import { HTTP_METHOD, WAITINGLIST_URL } from 'const';

import 'jsoneditor-react/es/editor.min.css';

const validationSchema = yup.object({
  email: yup.string().email().required(t('Components.Register.Required')),
  pronouns: yup.string().required(t('Components.Register.Required')),
  nickname: yup.string().required(t('Components.Register.Required')),
  legal: yup
    .boolean()
    .oneOf([true], t('Components.Register.DataProtection.Required')),
});

export function WaitingListForm() {
  const fetchFromBackend = useFetchFromBackend(true);
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onSubmit = (values) => {
    setLoading(true);
    const body = JSON.stringify(values);
    fetchFromBackend(WAITINGLIST_URL, { method: HTTP_METHOD.POST, body })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Components.WaitingList.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.status === 409) {
          showSnackbar(`${t('Components.WaitingList.EmailExists')}`, severity);
        } else if (response.status === 200) {
          setSuccess(true);
        } else {
          showSnackbar(
            `${t('Components.WaitingList.Submit')} ${result}`,
            severity
          );
        }
      })
      .catch((error) => {
        showSnackbar(
          `${t('Components.WaitingList.Submit')} ${error.text} (${
            error.statusCode
          })`,
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  function handleNewForm() {
    setSuccess(false);
    formik.handleReset({
      initialValues: {
        email: '',
        nickname: '',
        pronouns: '',
        legal: false,
      },
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit,
    });
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      nickname: '',
      pronouns: '',
      legal: false,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        maxWidth: '1200px',
        flexDirection: 'column',
      }}
    >
      {success ? (
        <>
          <Typography variant='caption' sx={{ fontSize: '2rem' }}>
            {`${t('Components.WaitingList.Submit')} ${t(
              'Components.WaitingList.Successful'
            )}`}
          </Typography>
          <Button
            color='success'
            onClick={handleNewForm}
            sx={{
              marginTop: '20px',
              '@media(max-width:5000px)': {
                fontSize: '1.8rem',
                lineHeight: '2rem',
              },

              '@media(max-width:1440px)': {
                fontSize: '1.5rem',
                lineHeight: '1.8rem',
              },

              '@media(max-width:800px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
            }}
            text={t('Components.WaitingList.Another')}
          />
        </>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='email'
            label={t('Components.WaitingList.Email')}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            helperText={formik.errors.email as string}
          />
          <TextField
            id='nickname'
            label={t('Components.WaitingList.Nickname')}
            value={formik.values.nickname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.nickname)}
            helperText={formik.errors.nickname as string}
          />
          <TextField
            id='pronouns'
            label={t('Components.WaitingList.Pronouns')}
            value={formik.values.pronouns}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.pronouns)}
            helperText={formik.errors.pronouns as string}
          />

          <Typography
            variant='caption'
            component='div'
            sx={{
              textWrap: 'balance',
              marginTop: '30px',
              '@media(max-width:5000px)': {
                fontSize: '1.5rem',
                lineHeight: '2rem',
              },

              '@media(max-width:1440px)': {
                fontSize: '1.25rem',
                lineHeight: '1.8rem',
              },

              '@media(max-width:800px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
            }}
          >
            <Trans
              i18nKey='Components.WaitingList.Legal'
              components={{
                url: <LinkText to='/privacy' target='_blank' />,
              }}
            />
          </Typography>
          <ThestralCheckbox
            id='legal'
            text={t('Components.Register.DataProtection.Checkbox')}
            checked={formik.values.legal}
            onChange={(event) =>
              formik.setFieldValue('legal', event.target.checked)
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
              '@media(max-width:5000px)': {
                fontSize: '1.8rem',
                lineHeight: '2rem',
              },

              '@media(max-width:1440px)': {
                fontSize: '1.5rem',
                lineHeight: '1.8rem',
              },

              '@media(max-width:800px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
            }}
          />
          {Boolean(formik.errors.legal) && (
            <Typography
              color='error'
              component='div'
              variant='caption'
              sx={{
                '@media(max-width:5000px)': {
                  fontSize: '1.8rem',
                  lineHeight: '2rem',
                },

                '@media(max-width:1440px)': {
                  fontSize: '1.5rem',
                  lineHeight: '1.8rem',
                },

                '@media(max-width:800px)': {
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                },
              }}
            >
              {typeof formik.errors.legal === 'string' && formik.errors.legal}
            </Typography>
          )}

          <Button
            color='success'
            onClick={formik.submitForm}
            sx={{
              marginTop: '20px',
              '@media(max-width:5000px)': {
                fontSize: '1.8rem',
                lineHeight: '2.rem',
              },

              '@media(max-width:1440px)': {
                fontSize: '1.5rem',
                lineHeight: '1.8rem',
              },

              '@media(max-width:800px)': {
                fontSize: '1rem',
                lineHeight: '1rem',
              },
            }}
            text={t('Generic.Enroll')}
          />
        </form>
      )}
    </Box>
  );
}
