import { ClubDataGrid } from 'pages/Clubs/ClubComponents/ClubDataGrid';
import { Box } from '@mui/material';

export default function AdminClubs() {
  return (
    <Box className='content' sx={{ margin: '20px auto 0 auto', width: '100%' }}>
      <ClubDataGrid />
    </Box>
  );
}
