import { ReactNode, useContext, useEffect, useState } from 'react';
import { Box, DialogContentText, Typography } from '@mui/material';
import { AccountPlus } from 'mdi-material-ui';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { Button, Dialog } from 'components/ui';

import { CHARACTERS_URL, HTTP_METHOD, USERS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { User } from 'types';
import { UserContext, CharacterContext } from 'contexts';
import { PlayerCharacterCard } from 'pages/PlayerCharacters/PlayerCharacterCompontents/PlayerCharacterCard';

type Props = {
  isNew?: boolean;
};

export function NewCharacter(props: Readonly<Props>): ReactNode {
  const fetchFromBackend = useFetchFromBackend();
  const auth = useAuth();
  const email = auth.user?.profile.email;

  const { data, triggerRefetch } = useFetch<User>(
    `${USERS_URL}/by-email/${email}`
  );

  const { user, setUser } = useContext(UserContext);
  const { char, setChar } = useContext(CharacterContext);
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  function openDialog() {
    setChar({ profileId: user?.profile?.id, new: true });
    setOpen(true);
  }

  function content(): ReactNode {
    return <PlayerCharacterCard />;
  }

  function warning(): ReactNode {
    return (
      <DialogContentText
        sx={{
          color: '#fff',
          fontFamily: 'Neuton',
          letterSpacing: '0.05rem',
          padding: '20px',
        }}
      >
        <Trans
          i18nKey={t('Components.Admin.Character.NewCharWarning', {
            name: char?.name,
          })}
        />
      </DialogContentText>
    );
  }

  function warningActions(): ReactNode {
    return (
      <Button
        onClick={() => {
          openDialog();
          setOpenWarning(false);
        }}
        color='success'
        text={<Typography>Ich bin mir sicher</Typography>}
      />
    );
  }

  function actions(): ReactNode {
    return (
      <Button
        onClick={saveCharacter}
        color='success'
        text={<Trans i18nKey='Generic.Save' />}
      />
    );
  }

  function saveCharacter() {
    const body = JSON.stringify({ ...char, profileId: user?.profile.id });
    fetchFromBackend(CHARACTERS_URL, { method: HTTP_METHOD.POST, body })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          triggerRefetch();
          setChar({ ...char, new: false });
        }

        showSnackbar(
          `${t('Components.Characters.NewSaved')} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error));

    setOpen(false);
  }

  if (!props.isNew) {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          height: 'auto',
          zIndex: 20,
          right: '0px',
          bottom: '0px',
        }}
      >
        <Button
          sx={{
            display: 'block',

            backgroundColor: 'primary.main',
            ':hover': {
              borderRadius: '5%',
              backgroundColor: 'secondary.main',
            },
            '& .MuiButtonBase-root:hover': {
              backgroundColor: 'secondary.main',
            },
            '& .MuiSvgIcon-root': {
              height: 'auto',
              width: '1.5em',
              color: '#1b1b1b',
              lineHeight: '0px',
              marginTop: '5px',
            },
          }}
          onClick={() => setOpenWarning(true)}
          text={<AccountPlus />}
        />
        {openWarning && (
          <Dialog
            sx={{
              '& .MuiPaper-root': {
                bgcolor: '#1e1e1e',
                marginBottom: '0',
              },
            }}
            open={openWarning}
            onClose={() => setOpenWarning(false)}
            title={t('Components.PlayerCharacters.New')}
            content={warning()}
            actions={warningActions()}
          />
        )}
        {open && (
          <Dialog
            sx={{
              '& .MuiPaper-root': {
                bgcolor: '#1e1e1e',
                marginBottom: '0',
              },
            }}
            open={open}
            onClose={() => setOpen(false)}
            title={t('Components.PlayerCharacters.New')}
            content={content()}
            actions={actions()}
          />
        )}
      </Box>
    );
  }
  //Alter Button wenn noch kein SC angelegt ist

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '60%',
      }}
    >
      <Button
        sx={{
          display: 'block',
          backgroundColor: 'primary.main',
          ':hover': {
            borderRadius: '5%',
            backgroundColor: 'secondary.main',
          },
          '& .MuiButtonBase-root:hover': {
            backgroundColor: 'secondary.main',
          },
          '& .MuiSvgIcon-root': {
            height: '3em',
            width: '3em',
            color: '#1b1b1b',
          },
        }}
        onClick={openDialog}
        text={
          <>
            <AccountPlus />
            <Typography
              variant='h2'
              sx={{
                fontFamily: 'Bebas',
                fontSize: '18pt',
                color: '#1b1b1b',
              }}
            >
              {t('Generic.NewChar')}
            </Typography>
          </>
        }
      />
      {open && (
        <Dialog
          sx={{
            '& .MuiPaper-root': {
              bgcolor: '#1e1e1e',
              marginBottom: '0',
            },
          }}
          open={open}
          onClose={() => setOpen(false)}
          title={t('Components.PlayerCharacters.New')}
          content={content()}
          actions={actions()}
        />
      )}
    </Box>
  );
}
