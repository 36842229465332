import { ReactNode } from 'react';
import { t } from 'i18next';

import { Characters } from '../Characters';
import { NonPlayerCharacterCard } from './NonPlayerCharacterComponents/NonPlayerCharacterCard';

export function NonPlayerCharacters(): ReactNode {
  return (
    <Characters
      title={t('Components.NonPlayerCharacters.Title')}
      dialogContent={<NonPlayerCharacterCard readonly />}
      npcs
    />
  );
}
