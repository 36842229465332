import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { Signup } from 'types';
import { SIGNUP_URL } from 'const';
import { useFetch } from 'func';
import SignupApprovalDialog from './ApprovalComponents/Signup/SignupApprovalDialog';
import SignupApprovalGrid from './ApprovalComponents/Signup/SignupApprovalGrid';
import { Headline } from 'components/ui';

export function SignupApproval() {
  const { data, loading, triggerRefetch } = useFetch<Signup[]>(SIGNUP_URL);
  const [current, setCurrent] = useState<Signup>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  function onRowClick(elem) {
    setCurrent(elem.row);
    setOpen(true);
  }

  return (
    <Box sx={{ marginTop: '-500px' }}>
      <Headline
        title={t('Components.Signup.Approvals.Title')}
        breadcrumbs={breadcrumbs}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data && (
            <SignupApprovalGrid
              onRowClick={onRowClick}
              signups={data}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>

      {current && (
        <SignupApprovalDialog
          signup={current}
          open={open}
          onClose={() => setOpen(false)}
          triggerRefetch={triggerRefetch}
        />
      )}
    </Box>
  );
}
