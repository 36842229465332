import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { TextField } from 'components/ui';

import { FormikErrors, FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>;
};

export default function OtherData(props: Readonly<Props>): ReactNode {
  const formik = props.formik;

  return (
    <>
      <Typography variant='ruleTitle' component='p'>
        <Trans i18next='Components.Profile.PersonalData.OtherData.Title' />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            name='profile.allergies'
            label={t('Components.Profile.PersonalData.OtherData.Allergies')}
            defaultValue={formik.values.profile?.allergies}
            value={formik.values.profile?.allergies}
            onChange={formik.handleChange}
            error={Boolean(
              (formik.errors.profile as FormikErrors<{ allergies: string }>)
                ?.allergies
            )}
            helperText={
              (formik.errors.profile as FormikErrors<{ allergies: string }>)
                ?.allergies
            }
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name='profile.eatingHabits'
            label={t('Components.Profile.PersonalData.OtherData.EatingHabits')}
            defaultValue={formik.values.profile?.eatingHabits}
            value={formik.values.profile?.eatingHabits}
            onChange={formik.handleChange}
            error={Boolean(
              (formik.errors.profile as FormikErrors<{ eatingHabits: string }>)
                ?.eatingHabits
            )}
            helperText={
              (formik.errors.profile as FormikErrors<{ eatingHabits: string }>)
                ?.eatingHabits
            }
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name='profile.phobiae'
            label={t('Components.Profile.PersonalData.OtherData.Phobiae')}
            defaultValue={formik.values.profile?.phobiae}
            value={formik.values.profile?.phobiae}
            onChange={formik.handleChange}
            error={Boolean(
              (formik.errors.profile as FormikErrors<{ phobiae: string }>)
                ?.phobiae
            )}
            helperText={
              (formik.errors.profile as FormikErrors<{ phobiae: string }>)
                ?.phobiae
            }
            readonly={formik.values.readonly}
          />
        </Grid>
      </Grid>
    </>
  );
}
