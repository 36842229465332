import { ReactNode, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import { EventCardItem } from './EventCardItem';

import { useFetch } from 'func';
import { Event } from 'types';
import { EVENTS_URL } from 'const';
import { DateTime } from 'luxon';
import { CardCircularProgress } from 'components/ui';

export function EventCard(): ReactNode {
  const { data, loading } = useFetch<Event[]>(EVENTS_URL);
  const [filtered, setFiltered] = useState<Event[]>();

  useEffect(() => {
    if (data) {
      const now = DateTime.now();
      const filteredEvents = data
        .filter((event) => DateTime.fromISO(event.otStart) >= now)
        .sort(
          (a, b) =>
            DateTime.fromISO(a.otStart).valueOf() -
            DateTime.fromISO(b.otStart).valueOf()
        );
      setFiltered(filteredEvents);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <CardCircularProgress />
      ) : (
        <Carousel autoPlay={false} animation='slide' height='336px'>
          {filtered?.map((item: Event, i) => (
            <EventCardItem key={item.name} item={item} isNext={i === 0} />
          ))}
        </Carousel>
      )}
    </>
  );
}
