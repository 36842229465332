import { ListAlt } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button, Dialog } from 'components/ui';
import { t } from 'i18next';
import { useState } from 'react';
import { Participant, User } from 'types';
import * as XLSX from 'xlsx';

type Props = {
  participants: Participant[];
  con: string;
  users: User[];
};

export default function GetParticipantsButton(props: Readonly<Props>) {
  const [open, setOpen] = useState<boolean>(false);
  const downloadParticipantExcel = (isAdmin) => {
    const workbook = XLSX.utils.book_new();

    const dataCharacter = props.participants.map((participant) => {
      const userData = props.users.find(
        (user) => user.id === participant.account.id
      );

      if (!isAdmin)
        return {
          Spitzname: participant.account.nickname,
          Vorname: userData?.firstname,
          Nachname: userData?.lastname,
          Charakter: participant.character.name,
          Haus: t(`Enum.House.${participant.character.house}`),
          Pronomen: userData?.profile.pronouns ?? '',
        };

      return {
        Spitzname: participant.account.nickname,
        Vorname: userData?.firstname,
        Nachname: userData?.lastname,
        Charakter: participant.character.name,
        Haus: t(`Enum.House.${participant.character.house}`),
        Plot: t(`Enum.PlotShort.${participant.character.plot}`),
        Jahrgang:
          t(`Enum.AgeGroup.${participant.character.ageGroup}`).charAt(0) === 'E'
            ? ''
            : t(`Enum.AgeGroup.${participant.character.ageGroup}`),
        BENEFIT:
          t(`Enum.Benefits.${participant.character.benefit}`).charAt(0) === 'E'
            ? ''
            : t(`Enum.Benefits.${participant.character.benefit}`),
        Bildrechte:
          t(`Enum.ImageRights.${userData?.profile.imageRights}`) ?? '',
        Phobien: userData?.profile.phobiae ?? '',
        Pronomen: userData?.profile.pronouns ?? '',
      };
    });

    const dataFood = props.users.map((user) => {
      return {
        Vorname: user.firstname,
        Nachname: user.lastname,
        Spitzname: user.profile.nickname ?? '',
        Essen: user.profile.eatingHabits ?? '',
        Allergien: user.profile.allergies ?? '',
      };
    });

    const worksheetCharacter = XLSX.utils.json_to_sheet(dataCharacter);
    const worksheetFood = XLSX.utils.json_to_sheet(dataFood);

    const columnWidthsCharacter = [
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 15 },
    ];

    const columnWidthsFood = [
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 30 },
      { wch: 30 },
    ];

    worksheetCharacter['!cols'] = columnWidthsCharacter;
    worksheetFood['!cols'] = columnWidthsFood;

    XLSX.utils.book_append_sheet(
      workbook,
      worksheetCharacter,
      'Spielcharaktere'
    );

    if (isAdmin) {
      XLSX.utils.book_append_sheet(workbook, worksheetFood, 'Essen');
    }

    XLSX.writeFile(workbook, `Spielcharaktere_${props.con}.xlsx`);
  };

  return (
    <>
      <Button
        startIcon={<ListAlt />}
        onClick={() => setOpen(true)}
        color='primary'
        text={t('Components.Admin.Events.Participant-List')}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        content={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Button
              startIcon={<ListAlt />}
              onClick={() => downloadParticipantExcel(true)}
              color='primary'
              text={t('Components.Admin.Events.Admin-List')}
            />
            <Button
              startIcon={<ListAlt />}
              onClick={() => downloadParticipantExcel(false)}
              color='secondary'
              text={t('Components.Admin.Events.NonAdmin-List')}
            />
          </Box>
        }
      />
    </>
  );
}
