import { MutableRefObject, ReactNode, useContext, useEffect } from 'react';
import { Box } from '@mui/material';

import { useFetch } from 'func';
import { Character } from 'types';
import { CharacterContext } from 'contexts';
import { NPCS_URL } from 'const';
import { General } from './General';
import { Special } from './Special';

type Props = {
  readonly?: boolean;
  refChar?: MutableRefObject<Character>;
};

export function NonPlayerCharacterCard(props: Readonly<Props>): ReactNode {
  const { char, setChar } = useContext(CharacterContext);
  const { data: character } = useFetch<Character>(`${NPCS_URL}/${char?.id}`);

  useEffect(() => {
    if (character) {
      setChar(character);
      if (props.refChar) {
        props.refChar.current = character;
      }
    }
  }, [character]);

  return (
    <Box>
      <General readonly={props.readonly} />
      <Special readonly={props.readonly} />
    </Box>
  );
}
