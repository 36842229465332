import { useEffect, useState } from 'react';
import { CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { Delete } from 'mdi-material-ui';

import { PEOPLE_URL, STATIC_URL } from 'const';
import { Character, DraperyCheck } from 'types';
import { useFetch } from 'func';
import { ThestralDataGrid } from 'components/ui';
import DraperyApprovalStatus from 'pages/Approvals/ApprovalComponents/Drapery/DraperyApprovalStatus';

type Props = {
  character: Character;
  onRowClick: (elem) => void;
  onDelete: (evt, row) => void;
};

export function PlayerDraperyCheckItem(props: Readonly<Props>) {
  const { data } = useFetch<DraperyCheck[]>(
    `${PEOPLE_URL}/${props.character.id}/drapery`
  );
  const [draperies, setDraperies] = useState<DraperyCheck[]>();

  useEffect(() => {
    if (data) {
      setDraperies(data);
    }
  }, [data]);

  return (
    <>
      <Typography variant='h4' component='div'>
        {props.character.name}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ThestralDataGrid
            rows={draperies}
            onRowClick={props.onRowClick}
            columns={[
              {
                field: 'thumbnail',
                headerName: t('Generic.Preview'),
                align: 'right',
                headerAlign: 'left',
                maxWidth: 300,
                renderCell: (params: GridRenderCellParams) => (
                  <CardMedia
                    component='img'
                    image={`${STATIC_URL}/uploads/${params.row.imagePath}`}
                    alt='Thumbnail Drapery'
                    sx={{ objectFit: 'contain' }}
                  />
                ),
              },
              {
                field: 'note',
                headerName: t('Generic.Note'),
                sortable: true,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
              },
              {
                field: 'edit',
                headerName: '',
                align: 'right',
                width: 250,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    <DraperyApprovalStatus drapery={params.row} />

                    <IconButton
                      sx={{ marginLeft: '5px' }}
                      edge='start'
                      color='inherit'
                      onClick={(event) => props.onDelete(event, params.row)}
                      aria-label='close'
                    >
                      <Delete color='primary' />
                    </IconButton>
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            hideFooter
            hideFooterPagination
            rowHeight={100}
            pageSizeOptions={[5, 10]}
            sx={{
              marginBottom: '100px',
              maxWidth: '900px',
              minHeight: '200px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
