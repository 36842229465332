import { ReactNode, useState, useContext, useEffect } from 'react';
import {
  Box,
  CardMedia,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { CreditCardOff, Fort } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import EuroIcon from '@mui/icons-material/Euro';

import { EVENTS_URL, HTTP_METHOD } from 'const';
import { UserContext } from 'contexts';
import {
  isPastMidnight,
  isTwoWeeksBefore,
  parseDate,
  useFetch,
  useFetchFromBackend,
  useSnackbar,
  validateIban,
} from 'func';
import { Event, Participant } from 'types';

import { Button, CardItem, Dialog, TextField } from 'components/ui';
import { useFormik } from 'formik';
import { TriggerContent } from 'components/ui/TriggerContent';
import { OwlpostNote } from './OwlpostNote';

type Props = {
  item: Event;
  isNext: boolean;
};

export function EventCardItem(props: Readonly<Props>): ReactNode {
  const event = props.item;
  const EVENT_URL = `${EVENTS_URL}/${event?.id}`;

  const { user } = useContext(UserContext);
  const fetchFromBackend = useFetchFromBackend();
  const { data: status, triggerRefetch } = useFetch<string>(
    `${EVENT_URL}/status/${user?.id}`
  );
  const { data: participants, triggerRefetch: refetchParticipants } = useFetch<
    Participant[]
  >(`${EVENT_URL}/participants`);

  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const [errorPermission, setErrorPermission] = useState(false);
  const [triggerConfirm, setTriggerConfirm] = useState<boolean>(false);
  const [triggertext, setTriggertext] = useState({
    trigger: '',
    undesired: '',
  });
  const [triggerDialogOpen, setTriggerDialogOpen] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (status && participants && props.isNext)
      if (status !== 'CANCELLED' && status !== 'UNREGISTERED') {
        const participant = participants.filter(
          (participant) => participant.account.id === user.id
        );

        !participant[0]?.account.triggerConfirm
          ? setTriggerDialogOpen(true)
          : setTriggerConfirm(true);
      }
  }, [status, participants, props.isNext]);

  function colorStatus() {
    switch (status) {
      case 'REGISTERED':
        return '#eab345';
      case 'PAID':
        return '#006207';
      case 'UNREGISTERED':
        return '#5f787b';
      case 'CANCELLED':
        return '#ff5252';
      case 'SEPA':
        return '#528cff';
      default:
        return '#5f787b';
    }
  }

  const price = isPastMidnight(event.priceChangeDate)
    ? isPastMidnight(event.priceChangeFinal)
      ? `${t('Components.Events.Pay.End')}`
      : event.price2
    : event.price1;

  const formik = useFormik({
    initialValues: {
      iban: user?.profile?.iban ?? '',
      permission: false,
      id: user?.id,
      accountHolder: `${user?.firstname} ${user?.lastname}`,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handlePaymentPerIban,
  });

  function validateName(name: string) {
    const nameParts = name.split(' ').filter((part) => part.trim() !== '');

    return nameParts.length >= 2;
  }

  function handlePaymentPerIban(value) {
    setErrorPermission(false);
    setErrorMsg('');
    const cleanIban = value?.iban?.trim().replaceAll(' ', '');
    const cleanName = value?.accountHolder?.trim();
    const isValidIban = cleanIban.length > 0 ? validateIban(cleanIban) : false;

    const isValidName = validateName(cleanName);

    if (!isValidIban) {
      setErrorMsg(t('Components.Events.Pay.IbanError'));
    }

    if (!value.permission) {
      setErrorPermission(true);
      return;
    }

    if (value.permission && isValidIban && isValidName) {
      const body = JSON.stringify({
        ...value,
        iban: cleanIban,
        accountHolder: cleanName,
      });
      setLoading(true);
      fetchFromBackend(`${EVENT_URL}/sepa`, {
        method: HTTP_METHOD.PATCH,
        body: body,
      })
        .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok
            ? t('Generic.Successful')
            : `${t('Generic.Failed')} (${response.status})`;

          if (response.ok) {
            triggerRefetch();
            setLoading(false);
            setPayModalOpen(false);
          }

          showSnackbar(
            `${t('Components.Events.Pay.IbanRegistration')} ${result}`,
            severity
          );
        })
        .finally(() => setLoading(false));
    }
  }

  function handlePaymentPerPaypal() {
    window.open('https://www.paypal.me/ThestralLARP', '_blank').focus();
    setPayModalOpen(false);
  }

  function cancelSEPA() {
    const body = JSON.stringify({});
    setLoading(true);

    fetchFromBackend(`${EVENT_URL}/sepa`, {
      method: HTTP_METHOD.PATCH,
      body: body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        if (response.ok) {
          triggerRefetch();
          formik.values.permission = false;
        }

        showSnackbar(
          `${t('Components.Events.Pay.CancelSEPA')} ${result}`,
          severity
        );
      })
      .finally(() => setLoading(false));
  }

  const changeRegistration = (update) => {
    const body = JSON.stringify({
      triggerConfirm: triggerConfirm,
      trigger: triggertext.trigger,
      undesired: triggertext.undesired,
    });
    setLoading(true);
    setCancelDialogOpen(false);
    fetchFromBackend(`${EVENT_URL}/${update}/${user?.id}`, {
      method: HTTP_METHOD.PUT,
      body: update === 'signup' ? body : null,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          triggerRefetch();
          refetchParticipants();
          if (update === 'cancel') {
            setTriggerConfirm(false);
          }
          setTriggertext({ trigger: '', undesired: '' });
        }

        const i18nKey = update === 'signup' ? 'SignUp' : 'Cancel';
        showSnackbar(
          `${t('Components.Events.' + i18nKey + '.Post')} ${result}`,
          severity
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {status !== 'PAID' &&
        status !== 'CANCELLED' &&
        status !== 'SEPA' &&
        status !== 'UNREGISTERED' &&
        !isPastMidnight(event.priceChangeFinal) && (
          <Button
            color='success'
            onClick={() => setPayModalOpen(true)}
            text={t('Components.Events.Pay.Post')}
            startIcon={<EuroIcon />}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              zIndex: '20',
            }}
          />
        )}
      {status === 'SEPA' && !isTwoWeeksBefore(event?.mandatePayDate) && (
        <Button
          color='error'
          onClick={() => cancelSEPA()}
          text={t('Components.Events.Pay.CancelSEPA')}
          startIcon={<CreditCardOff />}
          sx={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            zIndex: '20',
          }}
        />
      )}
      {triggerDialogOpen && (
        <Dialog
          open={triggerDialogOpen}
          onClose={() => {
            setTriggerDialogOpen(false);
          }}
          title={`${event?.name} - ${t(
            'Components.Events.SignUp.WarningTitle'
          )}`}
          content={
            <TriggerContent
              isWarning={true}
              isSignup={true}
              triggerConfirm={triggerConfirm}
              setTriggerConfirm={setTriggerConfirm}
              triggertext={triggertext}
              setTriggertext={setTriggertext}
              triggerRefetch={triggerRefetch}
              setLoading={setLoading}
              loading={loading}
              eventId={event.id}
              userId={user.id}
              refetchParticipants={refetchParticipants}
              setOpen={setTriggerDialogOpen}
            />
          }
        />
      )}
      {cancelDialogOpen && (
        <Dialog
          open={cancelDialogOpen}
          onClose={() => {
            setCancelDialogOpen(false);
          }}
          title={`${event?.name} - ${t('Components.Events.Cancel.Title')}`}
          content={
            <Typography sx={{ margin: 'auto' }}>
              {t('Components.Events.Cancel.Warning')}
            </Typography>
          }
          actions={
            <Button
              color='error'
              onClick={() => changeRegistration('cancel')}
              text={t('Components.Events.Cancel.Cancel')}
            />
          }
        />
      )}

      {payModalOpen && (
        <Dialog
          open={payModalOpen}
          onClose={() => {
            setPayModalOpen(false), setErrorMsg('');
          }}
          title={`${event?.name} ${t('Components.Events.Pay.Post')}`}
          caption={`${parseDate(event?.otStart)} - ${parseDate(event?.otEnd)}`}
          content={
            <Grid
              container
              sx={{
                alignItems: 'center',
                marginTop: '20px',
              }}
              gap={2}
            >
              <Grid item xs={12} md={3}>
                <Typography>{t('Components.Events.Price')}:</Typography>
                <Typography variant='caption'>{`${price} €`}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {isPastMidnight(event.priceChangeDate)
                    ? t('Components.Events.PriceChangeFinal')
                    : t('Components.Events.PriceChangeDate')}
                  :
                </Typography>
                <Typography variant='caption'>
                  {isPastMidnight(event.priceChangeDate)
                    ? `${parseDate(event?.priceChangeFinal)}`
                    : `${parseDate(event?.priceChangeDate)}`}
                </Typography>
              </Grid>
              {!isPastMidnight(event.priceChangeDate) && (
                <>
                  <Grid item xs={12} md={3}>
                    <Typography>
                      {t('Components.Events.Pay.MandatePayDate')}:
                    </Typography>
                    <Typography variant='caption'>{`${parseDate(
                      event?.mandatePayDate
                    )}`}</Typography>
                  </Grid>
                  <form>
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                        marginTop: '20px',
                        justifyContent: 'center',
                      }}
                      gap={2}
                    >
                      <Grid item xs={12}>
                        <TextField
                          id='mandate'
                          label={t('Components.Events.Pay.MandateRef')}
                          value={event.mandateRef}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='accountHolder'
                          label={t('Components.Events.Pay.Name')}
                          onChange={formik.handleChange}
                          value={formik.values.accountHolder}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                          error={!validateName(formik.values.accountHolder)}
                          inputProps={{ maxLength: 100 }}
                          helperText={
                            !validateName(formik.values.accountHolder)
                              ? t('Components.Events.Pay.NameError')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='iban'
                          label={t('Components.Events.Pay.Iban')}
                          onChange={formik.handleChange}
                          value={formik.values.iban}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                          error={errorMsg ? true : false}
                          inputProps={{ maxLength: 30 }}
                          helperText={errorMsg ? errorMsg : ''}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Checkbox
                          id='permission'
                          sx={{ outline: '#fff' }}
                          onChange={formik.handleChange}
                          value={formik.values.permission}
                        />
                        <Typography variant={'caption'}>
                          {t('Components.Events.Pay.Approval')}
                        </Typography>
                      </Grid>
                      {errorPermission && !formik.values.permission ? (
                        <Grid item xs={12} sx={{ marginTop: '-20px' }}>
                          <Typography
                            variant='caption'
                            sx={{ color: '#ff5252' }}
                          >
                            {t('Components.Events.Pay.PermissionError')}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Button
                          color='secondary'
                          onClick={formik.submitForm}
                          text={t('Components.Events.Pay.IbanPayment')}
                          sx={{ marginBottom: '10px', width: 'auto' }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </>
              )}

              <Grid item xs={12}>
                <Button
                  color='primary'
                  onClick={handlePaymentPerPaypal}
                  text={t('Components.Events.Pay.PaypalPayment')}
                  sx={{ marginBottom: '10px', width: 'auto' }}
                />
              </Grid>
            </Grid>
          }
        ></Dialog>
      )}
      <CardItem
        dialogTitle={event?.name}
        dialogActions={
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {status === 'UNREGISTERED' && (
              <Button
                color='success'
                onClick={() => changeRegistration('signup')}
                text={t('Components.Events.SignUp.Title')}
                disabled={!triggerConfirm}
              />
            )}
            {status == 'REGISTERED' && (
              <Button
                color='success'
                onClick={() => setPayModalOpen(true)}
                text={t('Components.Events.Pay.Title')}
              />
            )}
            {status == 'CANCELLED' && (
              <Button
                color='success'
                onClick={() => changeRegistration('signup')}
                text={t('Components.Events.SignUp.Title')}
                disabled={!triggerConfirm}
              />
            )}

            {status === 'SEPA' && (
              <Button
                color='primary'
                onClick={() => cancelSEPA()}
                text={t('Components.Events.Pay.CancelSEPA')}
              />
            )}
            {status !== 'CANCELLED' && (
              <Button
                color='error'
                onClick={() => setCancelDialogOpen(true)}
                text={t('Components.Events.Cancel.Title')}
              />
            )}
          </Box>
        }
        dialogContent={
          loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h6'>
                <Trans i18nKey='Components.Events.Status' />
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  color: colorStatus(),
                  fontSize: '1.2rem',
                  marginBottom: '10px',
                }}
              >
                {t('Enum.ParticipationStatus.' + status)}
              </Typography>
              {status === 'SEPA' && (
                <>
                  <Typography variant='h6'>
                    <Trans i18nKey='Components.Events.Pay.Iban' />
                  </Typography>
                  <Typography
                    variant='caption'
                    sx={{
                      marginBottom: '20px',
                      fontSize: '1.2rem',
                    }}
                  >
                    {user?.profile?.iban}
                  </Typography>
                  <Typography variant='h6'>
                    <Trans i18nKey='Components.Events.Pay.MandatePayDate' />
                  </Typography>
                  <Typography
                    variant='caption'
                    sx={{
                      marginBottom: '20px',
                      fontSize: '1.2rem',
                    }}
                  >
                    {parseDate(event?.mandatePayDate)}
                  </Typography>
                </>
              )}
              {status === 'PAID' && (
                <>
                  <OwlpostNote event={event} />
                  <TriggerContent
                    isSignup={false}
                    triggerConfirm={triggerConfirm}
                    setTriggerConfirm={setTriggerConfirm}
                    triggertext={triggertext}
                    setTriggertext={setTriggertext}
                    triggerRefetch={triggerRefetch}
                    setLoading={setLoading}
                    loading={loading}
                    eventId={event.id}
                  />
                </>
              )}
              {(status === 'UNREGISTERED' || status === 'CANCELLED') && (
                <TriggerContent
                  isSignup={true}
                  triggerConfirm={triggerConfirm}
                  setTriggerConfirm={setTriggerConfirm}
                  triggertext={triggertext}
                  setTriggertext={setTriggertext}
                  triggerRefetch={triggerRefetch}
                  setLoading={setLoading}
                  loading={loading}
                  eventId={event.id}
                />
              )}
            </Box>
          )
        }
        cardMedia={
          <CardMedia
            sx={{
              height: '180px',
              margin: 'auto',
              width: '65%',
              paddingTop: '10px',
              cursor: 'pointer',
            }}
          >
            <Fort
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </CardMedia>
        }
        cardContent={
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography
                  variant='h5'
                  gutterBottom
                  sx={{ textAlign: 'center', marginBottom: '0' }}
                  color='black'
                >
                  {event?.name}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{ color: '#9e9e9e', textAlign: 'center' }}
                >
                  {parseDate(event?.otStart)} - {parseDate(event?.otEnd)}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{ color: colorStatus(), textAlign: 'center' }}
                >
                  {t('Enum.ParticipationStatus.' + status)}
                </Typography>
              </Box>
            )}
          </>
        }
      />
    </Box>
  );
}
