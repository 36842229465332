import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { TextField } from 'components/ui';

import { FormikProps, FormikValues } from 'formik';

import { CountryCodeSelect } from './CountryCodeSelect';

type Props = {
  formik: FormikProps<FormikValues>;
};

export default function LegalData(props: Readonly<Props>): ReactNode {
  const formik = props.formik;

  return (
    <>
      <Typography variant='ruleTitle' component='p'>
        <Trans i18next='Components.Profile.PersonalData.LegalData.Title' />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name='firstname'
            label={t('Components.Profile.PersonalData.LegalData.FirstName')}
            defaultValue={formik.values.firstname}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.firstname)}
            helperText={formik.errors.firstname as string}
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='lastname'
            label={t('Components.Profile.PersonalData.LegalData.LastName')}
            defaultValue={formik.values.lastname}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.lastname)}
            helperText={formik.errors.lastname as string}
            readonly={formik.values.readonly}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            name='streetName'
            label={t('Components.Profile.PersonalData.LegalData.StreetName')}
            defaultValue={formik.values.streetName}
            value={formik.values.streetName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.streetName)}
            helperText={formik.errors.streetName as string}
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name='streetNumber'
            label={t('Components.Profile.PersonalData.LegalData.StreetNumber')}
            defaultValue={formik.values.streetNumber}
            value={formik.values.streetNumber}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.streetNumber)}
            helperText={formik.errors.streetNumber as string}
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='addressOptional'
            label={t(
              'Components.Profile.PersonalData.LegalData.AddressOptional'
            )}
            defaultValue={formik.values.addressOptional}
            value={formik.values.addressOptional}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addressOptional)}
            helperText={formik.errors.addressOptional as string}
            readonly={formik.values.readonly}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name='zipCode'
            label={t('Components.Profile.PersonalData.LegalData.ZipCode')}
            defaultValue={formik.values.zipCode}
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.zipCode)}
            helperText={formik.errors.zipCode as string}
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='city'
            label={t('Components.Profile.PersonalData.LegalData.City')}
            defaultValue={formik.values.city}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.city)}
            helperText={formik.errors.city as string}
            readonly={formik.values.readonly}
          />
        </Grid>
        <Grid item xs={6}>
          <CountryCodeSelect
            value={formik.values.country}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.country)}
            readOnly={formik.values.readonly}
          />
        </Grid>
      </Grid>
    </>
  );
}
