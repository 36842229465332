import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Owl, Plus } from 'mdi-material-ui';

import { Delete, Newspaper, People, Warning } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';

import { EVENTS_URL, EMPTY } from 'const';
import { parseDate, useFetch, useSnackbar } from 'func';
import { Event, Roles } from 'types';

import {
  Button,
  ThestralDataGrid,
  TriggerDialog,
  DeleteDialog,
} from 'components/ui';

import { useAuth } from 'react-oidc-context';
import { OwlpostDialog } from './DialogComponents/OwlpostDialog';
import { EventDialog } from './DialogComponents/EventDialog';
import { ProphetDialog } from './DialogComponents/ProphetDialog';
import { ParticipationDialog } from './DialogComponents/ParticipationDialog';

export default function AdminEvents() {
  const auth = useAuth();
  const { data, triggerRefetch } = useFetch<Event[]>(`${EVENTS_URL}`);

  const { showSnackbar } = useSnackbar();

  const [events, setEvents] = useState<Event[]>();
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [loading, setLoading] = useState<boolean>(true);
  const [owlpostOpen, setOwlpostOpen] = useState<boolean>(false);
  const [newOpen, setNewOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [participationOpen, setParticipationOpen] = useState<boolean>(false);
  const [prophetOpen, setProphetOpen] = useState<boolean>(false);
  const [triggerOpen, setTriggerOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [colVisModel, setColVisModel] = useState({});

  useEffect(() => {
    setColVisModel({ otEnd: !isSm, otStart: !isSm });
  }, [isSm]);

  useEffect(() => {
    if (data) {
      setEvents(data);
      setLoading(false);
    }
  }, [data]);

  function hasAnyRole(allowed: Array<string>) {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.length > 0 && roles?.some((r) => allowed.includes(r));
    }
    return false;
  }

  const onRowClick = (elem) => {
    setCurrentEvent(elem.row);
    setNewOpen(true);
  };

  const onNewEvent = () => {
    setCurrentEvent(null);
    setNewOpen(true);
  };

  const onOwlpost = (row) => {
    setCurrentEvent(row);
    setOwlpostOpen(true);
  };

  const onProphet = (row) => {
    setCurrentEvent(row);
    setProphetOpen(true);
  };

  const onDelete = (row) => {
    setCurrentEvent(row);
    setDeleteOpen(true);
  };

  const onParticipants = (row) => {
    setCurrentEvent(row);
    setParticipationOpen(true);
  };

  const onTrigger = (row) => {
    setCurrentEvent(row);
    setTriggerOpen(true);
  };

  return (
    <Box
      className='content'
      sx={{ margin: '75px auto 0 auto', maxWidth: '900px' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            color='primary'
            startIcon={<Plus sx={{ color: '#fff' }} />}
            onClick={onNewEvent}
            text={
              <Typography
                component='div'
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Trans i18nKey='Components.Admin.Events.New' />
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ThestralDataGrid
            rows={events || EMPTY}
            rowHeight={100}
            columnVisibilityModel={colVisModel}
            columns={[
              {
                field: 'name',
                headerName: t('Generic.Name'),
                sortable: true,
                flex: 2,
              },
              {
                field: 'otStart',
                headerName: t('Components.Events.Start'),
                sortable: true,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params: GridRenderCellParams) =>
                  loading ? '' : parseDate(params.row.otStart),
              },
              {
                field: 'otEnd',
                headerName: t('Components.Events.End'),
                sortable: true,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params: GridRenderCellParams) =>
                  loading ? '' : parseDate(params.row.otEnd),
              },
              {
                field: 'actions',
                headerName: t('Generic.Actions'),
                align: 'right',
                headerAlign: 'right',
                width: 50,
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                  return loading ? (
                    <></>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onProphet(params.row);
                        }}
                      >
                        <Tooltip
                          title={t('Components.Admin.Events.Tooltip.Summary')}
                        >
                          <Newspaper color='primary' />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onOwlpost(params.row);
                        }}
                      >
                        <Tooltip
                          title={t('Components.Admin.Events.Owlpost.Title')}
                        >
                          <Owl color='primary' />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onParticipants(params.row);
                        }}
                      >
                        <Tooltip
                          title={t(
                            'Components.Admin.Events.Tooltip.Participations'
                          )}
                        >
                          <People color='primary' />
                        </Tooltip>
                      </IconButton>
                      {hasAnyRole(['GAME_MASTER_MAIN']) && (
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            onTrigger(params.row);
                          }}
                        >
                          <Tooltip
                            title={t('Components.Admin.Events.Tooltip.Trigger')}
                          >
                            <Warning color='primary' />
                          </Tooltip>
                        </IconButton>
                      )}

                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.row);
                        }}
                      >
                        <Tooltip title={t('Generic.Delete')}>
                          <Delete color='primary' />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  );
                },
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            loading={loading}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>

      {currentEvent && (
        <OwlpostDialog
          open={owlpostOpen}
          onClose={() => setOwlpostOpen(false)}
          event={currentEvent}
        />
      )}
      <EventDialog
        open={newOpen}
        onClose={() => setNewOpen(false)}
        triggerRefetch={triggerRefetch}
        showSnackbar={showSnackbar}
        event={currentEvent}
      />
      {currentEvent && (
        <ProphetDialog
          open={prophetOpen}
          onClose={() => setProphetOpen(false)}
          triggerRefetch={triggerRefetch}
          event={currentEvent}
        />
      )}
      {currentEvent && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${EVENTS_URL}/${currentEvent?.id}`}
          i18nBase='Components.Admin.Events'
        />
      )}
      {currentEvent && (
        <ParticipationDialog
          open={participationOpen}
          onClose={() => setParticipationOpen(false)}
          event={currentEvent}
        />
      )}
      {currentEvent && (
        <TriggerDialog
          open={triggerOpen}
          onClose={() => setTriggerOpen(false)}
          event={currentEvent}
        />
      )}
    </Box>
  );
}
