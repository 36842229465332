import { ReactNode, useState, useEffect } from 'react';
import { Close, Check, RadioButtonUnchecked } from '@mui/icons-material';
import { IconColor } from 'const';
import { IconButton } from '@mui/material';

type Props = {
  onClick?: (evt) => void;
  state?: boolean;
  minWidth?: string;
};

export default function ApprovalStatus(props: Readonly<Props>) {
  const [color, setColor] = useState<string>();
  const [icon, setIcon] = useState<ReactNode>();
  useEffect(() => {
    switch (props.state) {
      case false:
        setColor(IconColor.X);
        setIcon(<Close />);
        break;
      case true:
        setColor(IconColor.Check);
        setIcon(<Check />);
        break;
      default:
        setColor(IconColor.Circle);
        setIcon(<RadioButtonUnchecked />);
    }
  }, [props.state]);

  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        color,
      }}
    >
      {icon}
    </IconButton>
  );
}
