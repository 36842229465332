import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import { Dialog, Headline } from 'components/ui';
import { CONTENT_URL, HTTP_METHOD } from 'const';
import { JsonContext } from 'contexts';
import { useFetch, useFetchFromBackend } from 'func';
import { t } from 'i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Roles, ThestralContent } from 'types';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { usePrevious } from 'react-use';
import { useAuth } from 'react-oidc-context';
import { TabContent } from '../Rules/RulesComponents/RulesTab/TabContent';

type Props = {
  name: string;
};

export function PrivacyImprint(props: Readonly<Props>) {
  const auth = useAuth();
  const fetchFromBackend = useFetchFromBackend();
  const { data, triggerRefetch } = useFetch<ThestralContent>(
    `${CONTENT_URL}/slug/public/${props.name}`
  );
  const [json, setJson] = useState();
  const jsonContextValue = useMemo(() => ({ json, setJson }), [json, setJson]);
  const prevJson = usePrevious(json);
  const i18nTitle = props.name == 'privacy' ? 'Privacy' : 'Imprint';

  const [openEdit, setOpenEdit] = useState<boolean>();

  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  const onSave = () => {
    if (json !== prevJson) {
      const body = JSON.stringify({
        title: props.name,
        content: JSON.stringify(json),
      });
      fetchFromBackend(`${CONTENT_URL}/${data.id}`, {
        method: HTTP_METHOD.PUT,
        body,
      })
        .then((response) => {
          if (response.ok) {
            triggerRefetch();
          }
        })
        .catch((error) => console.error(error));
    }
    setOpenEdit(false);
  };

  useEffect(() => {
    if (data) {
      setJson(JSON.parse(data?.content));
    }
  }, [data]);

  function content(): ReactNode {
    return (
      <Editor
        history
        value={json}
        onChange={(newJson) => setJson(newJson)}
        expanded
      />
    );
  }

  const isAdmin = () => {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some((r) => ['ADMIN'].includes(r)) ?? false;
    }
    return false;
  };

  return (
    <Box className='content' sx={{ marginTop: '50px' }}>
      <Headline
        title={t(`Components.${i18nTitle}.Title`)}
        breadcrumbs={breadcrumbs}
      />
      {isAdmin() && (
        <IconButton
          aria-label='edit'
          onClick={() => setOpenEdit(true)}
          sx={{ float: 'right' }}
        >
          <Edit sx={{ color: '#eab354' }} />
        </IconButton>
      )}

      <JsonContext.Provider value={jsonContextValue}>
        <TabContent name={props.name} />
      </JsonContext.Provider>

      {openEdit && (
        <Dialog
          open={openEdit}
          onClose={onSave}
          title={t('Generic.Edit')}
          content={content()}
          sx={{
            '.jsoneditor': { height: '750px' },
          }}
        />
      )}
    </Box>
  );
}
