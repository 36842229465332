import { ReactNode, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { t } from 'i18next';

import { Button, Headline } from 'components/ui';

import { DRAPERIES_URL, HTTP_METHOD, USERS_URL } from 'const';
import { Character, DraperyCheck, User } from 'types';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { PlayerDraperyCheckItem } from './DraperyComponents/PlayerDraperyCheckItem';
import DraperyNew from './DraperyComponents/DraperyNew';
import DraperyEdit from './DraperyComponents/DraperyEdit';

export function PlayerDrapery(): ReactNode {
  const auth = useAuth();
  const fetchFromBackend = useFetchFromBackend();
  const [user, setUser] = useState<User>();

  const { data, triggerRefetch } = useFetch<User>(
    `${USERS_URL}/by-email/${auth?.user?.profile?.email}`
  );
  const { data: characters, triggerRefetch: refetchChars } = useFetch<
    Character[]
  >(user ? `${USERS_URL}/profiles/${data?.profile?.id}/characters` : null);

  useEffect(() => {
    if (data) {
      setUser(data);
      refetchChars();
    }
  }, [data]);

  const [current, setCurrent] = useState<DraperyCheck>();

  const [open, setOpen] = useState<boolean>(false);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const title = t('Components.Drapery.Title');
  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  const onRowClick = (elem) => {
    setCurrent(elem.row);
    setDetailOpen(true);
  };

  const onNew = () => {
    setOpen(true);
  };

  function onDelete(event, elem) {
    event.stopPropagation();
    fetchFromBackend(`${DRAPERIES_URL}/${elem.id}`, {
      method: HTTP_METHOD.DELETE,
    }).then((response) => {
      const severity = response.status < 400 ? 'success' : 'error';
      const result = response.ok
        ? t('Generic.Successful')
        : `${t('Generic.Failed')} (${response.status})`;

      if (response.ok) {
        triggerRefetch();
      }
      showSnackbar(`${t('Components.Drapery.Deletion')} ${result}`, severity);
    });
  }

  return (
    <Box className='content' sx={{ width: '100%', marginTop: '20px' }}>
      <Headline title={title} breadcrumbs={breadcrumbs} />

      <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
        <Grid xs={12} sx={{ padding: '20px' }}>
          <Box>
            <Button
              sx={{ marginBottom: '20px' }}
              onClick={onNew}
              color='success'
              text={<Trans i18nKey='Components.Drapery.New' />}
            />
            {characters?.map((item: Character) => {
              return (
                <Box key={item.id}>
                  <PlayerDraperyCheckItem
                    character={item}
                    onRowClick={onRowClick}
                    onDelete={onDelete}
                  />
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>

      <DraperyNew
        open={open}
        onClose={() => setOpen(false)}
        profile={user?.profile}
        fetchCallback={() => triggerRefetch()}
        characters={characters}
      />
      <DraperyEdit
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        drapery={current}
        fetchCallback={() => triggerRefetch()}
        onDelete={onDelete}
      />
    </Box>
  );
}
