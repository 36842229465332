import {
  Grid,
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Check,
  Circle,
  Close,
  Delete,
  PersonOff,
  RecentActors,
  Workspaces,
} from '@mui/icons-material';
import { AccountPlus, PrinterOutline } from 'mdi-material-ui';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { Button, DeleteDialog, ThestralDataGrid } from 'components/ui';

import { EMPTY, USERS_URL } from 'const';
import { useFetch } from 'func';
import { useState, useEffect } from 'react';
import { User } from 'types';
import { WaitingListDialog } from 'pages/WaitingList/WaitinglistComponents/WaitingListDialog';
import ProfileDialog from 'pages/Profile/ProfileComponents/ProfileDialog';
import { RolesDialog } from './DialogComponents/RolesDialog';
import { InviteDialog } from './DialogComponents/InviteDialog';

export default function AdminUserList() {
  const { data, triggerRefetch } = useFetch<User[]>(USERS_URL);
  const [users, setUsers] = useState<User[]>();
  const filteredUsers = users?.filter((user) => user.status === 'ACTIVE');

  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<User>();

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [rolesOpen, setRolesOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);
  const [waitingOpen, setWaitingOpen] = useState<boolean>(false);

  const theme = useTheme();
  const [colVisModel, setColVisModel] = useState({});
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setColVisModel({
      email: !isSm,
      dateOfBirth: !isSm,
      'profile.imageRights': !isSm,
    });
  }, [isSm]);

  useEffect(() => {
    if (data) {
      setUsers(data);
      setLoading(false);
    }
  }, [data]);

  const onRoles = (event, user: User) => {
    event.stopPropagation();
    setRolesOpen(true);
    setCurrentUser(user);
  };

  const onDelete = (event, user: User) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setCurrentUser(user);
  };

  const imageRights = (user: User) => {
    const rights = user?.profile?.imageRights;
    let icon = <Circle color='primary' />;

    switch (rights) {
      case 'TAGME':
        icon = <Check color='primary' />;
        break;
      case 'NODISCLOSURE':
        icon = <Close color='primary' />;
        break;
      case 'NOTAGGING':
        icon = <PersonOff color='primary' />;
        break;
    }
    return <Tooltip title={t('Enum.ImageRights.' + rights)}>{icon}</Tooltip>;
  };

  const openProfile = (elem) => {
    setCurrentUser(elem.row);
    setOpen(true);
  };

  const print = () => {
    const entries = users
      .filter((user) => user.status === 'ACTIVE')
      .map((user) => ({
        name: user.profile?.nickname,
        image: t('Enum.ImageRights.' + user.profile?.imageRights),
        instagram: user.profile?.socialMediaAccounts?.INSTAGRAM,
        pinterest: user.profile?.socialMediaAccounts?.PINTEREST,
      }));
    entries.sort((a, b) => {
      if (a.image < b.image) return 1;
      if (a.image > b.image) return -1;
      return 0;
    });
    const a = window.open('', '', 'width=1200');
    const headers = [
      t('Generic.Name'),
      t('Components.Profile.ImageRights.Title'),
      'Instagram',
      'Pinterest',
      'X',
    ];

    let tableHtml =
      '<html><head><style>@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; }} th, td {border-right: 1px solid black; padding: 8px; text-align: left} th {background-color: #f2f2f2} tr:nth-child(even){background-color: #f9f9f9}</style></head><body><table style="border 1px solid black; border-collapse: collapse; width: 100%">';
    tableHtml += '<thead><tr>';
    headers.forEach(
      (header) => (tableHtml += `<th align="left">${header}</th>`)
    );
    tableHtml += '</tr></thead><tbody>';

    entries.forEach((entry) => {
      tableHtml += '<tr style="border-right: 1px solid red;">';
      tableHtml += `<td>${entry.name || ''}</td>`;
      tableHtml += `<td>${entry.image || ''}</td>`;
      tableHtml += `<td>${entry.instagram || ''}</td>`;
      tableHtml += `<td>${entry.pinterest || ''}</td>`;

      tableHtml += '</tr>';
    });

    tableHtml += '</tbody></table></body></html>';

    a.document.write(tableHtml);
    a.document.close();

    a.print();
    setTimeout(() => a.close(), 1);
  };

  const onInvite = () => {
    setInviteOpen(true);
  };

  const onWaiting = () => {
    setWaitingOpen(true);
  };

  return (
    <Box
      className='content'
      sx={{
        margin: '75px auto 0 auto',
        maxWidth: '900px',
        '@media (max-width:600px)': {
          marginTop: 0,
        },
      }}
    >
      <Grid container spacing={2} sx={{ padding: '10px' }}>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
        >
          <Button
            color='success'
            startIcon={<AccountPlus />}
            onClick={onInvite}
            text={t('Components.Admin.Users.Invite')}
          />

          <Button
            color='secondary'
            startIcon={<RecentActors />}
            onClick={onWaiting}
            text={t('Components.Admin.Users.WaitingList')}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            color='primary'
            startIcon={<PrinterOutline />}
            variant='outlined'
            sx={{ float: 'right' }}
            onClick={print}
            text={t('Components.Admin.Users.Print')}
          />
        </Grid>

        <Grid item xs={12}>
          <ThestralDataGrid
            rowHeight={75}
            rows={filteredUsers || EMPTY}
            columnVisibilityModel={colVisModel}
            sx={{
              wordBreak: 'break-all',
              '@media (max-width:600px)': {
                height: '100 !important',
              },
            }}
            columns={[
              {
                field: 'name',
                headerName: t('Generic.Name'),
                sortable: true,
                flex: 3,
                filterable: true,
                valueGetter: (_, row) => `${row.lastname}, ${row.firstname}`,
              },
              {
                field: 'profile.nickname',
                headerName: t('Generic.Nickname'),
                sortable: true,
                flex: 1,
                valueGetter: (_, row) => {
                  return row?.profile?.nickname;
                },
              },
              {
                field: 'email',
                headerName: t('Generic.Email'),
                sortable: true,
                flex: 1,
              },
              {
                field: 'dateOfBirth',
                headerName: t('Generic.DateOfBirth'),
                sortable: true,
                flex: 1,
                valueGetter: (_, row) => {
                  return loading
                    ? ''
                    : DateTime.fromISO(row.dateOfBirth).toFormat('dd.MM.yyyy');
                },
              },
              {
                field: 'profile.imageRights',
                headerName: t(
                  'Components.Profile.DataProtectionData.ImageRights.Title'
                ),
                sortable: true,
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                  return loading ? <></> : imageRights(params.row);
                },
              },
              {
                field: 'actions',
                headerName: t('Generic.Actions'),
                align: 'right',
                headerAlign: 'right',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                  return loading ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <IconButton
                        sx={{
                          '@media (max-width:600px)': {
                            padding: 0,
                          },
                        }}
                        onClick={(event) => onRoles(event, params.row)}
                      >
                        <Tooltip title={t('Generic.Roles')}>
                          <Workspaces color='primary' />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={(event) => onDelete(event, params.row)}
                      >
                        <Tooltip title={t('Generic.DeleteUser')}>
                          <Delete color='primary' />
                        </Tooltip>
                      </IconButton>
                    </div>
                  );
                },
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: 'lastname', sort: 'asc' }],
              },
            }}
            loading={loading}
            onRowClick={openProfile}
          />
        </Grid>
      </Grid>

      <WaitingListDialog
        open={waitingOpen}
        onClose={() => setWaitingOpen(false)}
        title={t('Components.Admin.Users.WaitingList')}
      />
      {open && (
        <ProfileDialog
          open={open}
          onClose={() => setOpen(false)}
          id={currentUser?.id}
        />
      )}
      {currentUser && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${USERS_URL}/${currentUser?.id}`}
          i18nBase='Components.Admin.Users'
        />
      )}
      {currentUser && (
        <RolesDialog
          open={rolesOpen}
          onClose={() => setRolesOpen(false)}
          userId={currentUser?.id}
        />
      )}
      <InviteDialog
        open={inviteOpen}
        title={t('Components.Admin.Users.Invite')}
        onClose={() => setInviteOpen(false)}
      />
    </Box>
  );
}
