import { Box, Typography } from '@mui/material';
import { useMaintenanceMode } from 'func';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Maintenance() {
  const isMaintenanceMode = useMaintenanceMode();
  const nav = useNavigate();

  useEffect(() => {
    if (!isMaintenanceMode) {
      nav('/');
    }
  }, [isMaintenanceMode]);

  return (
    <Box
      sx={{
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80vw',
        height: '70vh',
        gap: '50px',
      }}
    >
      <Typography
        variant='h1'
        sx={{
          fontSize: '3rem',
          fontWeight: '700',
        }}
      >
        {t('Generic.Maintenance.Title')}
      </Typography>

      <Typography
        variant='body2'
        sx={{
          fontSize: '2rem',
          fontWeight: '500',
          textAlign: 'center',
          '@media(max-width: 600px)': {
            fontSize: '1.5rem',
          },
        }}
      >
        {t('Generic.Maintenance.Caption')}
      </Typography>
    </Box>
  );
}
