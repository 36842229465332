import { ReactNode, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Spotify } from 'mdi-material-ui';
import { HelpOutline, Instagram, Pinterest } from '@mui/icons-material';
import { Trans } from 'react-i18next';

import { Dialog, TextField } from 'components/ui';

import { FormikErrors, FormikProps, FormikValues } from 'formik';

import Help_Instagram from 'assets/images/help_insta.png';
import Help_Spotify from 'assets/images/help_spotify.png';
import Help_Pinterest from 'assets/images/help_pinterest.png';
import { t } from 'i18next';
import { RulesParagraph } from 'pages/Rules/RulesComponents/RulesParagraph';
import { RulesBlock } from 'pages/Rules/RulesComponents/RulesBlock/RulesBlock';

type Props = {
  formik: FormikProps<FormikValues>;
};

export default function SocialMediaData(props: Readonly<Props>): ReactNode {
  const formik = props.formik;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Typography variant='ruleTitle' component='p'>
        <Trans i18next='Components.Profile.PersonalData.SocialMediaData.Title' />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name='INSTAGRAM'
            defaultValue={formik.values.profile?.socialMediaAccounts?.INSTAGRAM}
            icon={<Instagram sx={{ mr: 1, my: 0.5 }} />}
            value={formik.values.profile?.socialMediaAccounts?.INSTAGRAM}
            onChange={(event) =>
              formik.setFieldValue(
                'profile.socialMediaAccounts.INSTAGRAM',
                event.target.value
              )
            }
            error={Boolean(
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { INSTAGRAM: string };
                }>
              )?.socialMediaAccounts.INSTAGRAM
            )}
            helperText={
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { INSTAGRAM: string };
                }>
              )?.socialMediaAccounts.INSTAGRAM
            }
            readonly={formik.values.readonly}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  sx={{ color: '#ffffff' }}
                  onClick={() => setOpen(true)}
                >
                  <HelpOutline />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='SPOTIFY'
            defaultValue={formik.values.profile?.socialMediaAccounts?.SPOTIFY}
            icon={<Spotify sx={{ mr: 1, my: 0.5 }} />}
            value={formik.values.profile?.socialMediaAccounts?.SPOTIFY}
            onChange={(event) =>
              formik.setFieldValue(
                'profile.socialMediaAccounts.SPOTIFY',
                event.target.value
              )
            }
            error={Boolean(
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { SPOTIFY: string };
                }>
              )?.socialMediaAccounts.SPOTIFY
            )}
            helperText={
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { SPOTIFY: string };
                }>
              )?.socialMediaAccounts.SPOTIFY
            }
            readonly={formik.values.readonly}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  sx={{ color: '#ffffff' }}
                  onClick={() => setOpen(true)}
                >
                  <HelpOutline />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='PINTEREST'
            defaultValue={formik.values.profile?.socialMediaAccounts?.PINTEREST}
            icon={<Pinterest sx={{ mr: 1, my: 0.5 }} />}
            value={formik.values.profile?.socialMediaAccounts?.PINTEREST}
            onChange={(event) =>
              formik.setFieldValue(
                'profile.socialMediaAccounts.PINTEREST',
                event.target.value
              )
            }
            error={Boolean(
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { PINTEREST: string };
                }>
              )?.socialMediaAccounts.PINTEREST
            )}
            helperText={
              (
                formik.errors.profile as FormikErrors<{
                  socialMediaAccounts: { PINTEREST: string };
                }>
              )?.socialMediaAccounts.PINTEREST
            }
            readonly={formik.values.readonly}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  sx={{ color: '#ffffff' }}
                  onClick={() => setOpen(true)}
                >
                  <HelpOutline />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title={'Social Media Links - Hilfe'}
        content={
          <>
            <RulesParagraph text={t('Components.Profile.Help.Paragraph')} />

            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
              <Grid item xs={6}>
                <Instagram htmlColor='#ffffff' />
                <br />
                <RulesBlock
                  items={t('Components.Profile.Help.Instagram', {
                    returnObjects: true,
                  })}
                />
                <Box
                  component='img'
                  sx={{
                    width: '100%',
                    boxShadow: '10px 10px 10px #1e1e1e',
                  }}
                  src={Help_Instagram}
                  alt='Help Instagram'
                />
              </Grid>
              <Grid item xs={6}>
                <Spotify htmlColor='#ffffff' />
                <br />
                <RulesBlock
                  items={t('Components.Profile.Help.Spotify', {
                    returnObjects: true,
                  })}
                />
                <Box
                  component='img'
                  sx={{
                    width: '100%',
                    boxShadow: '10px 10px 10px #1e1e1e',
                  }}
                  src={Help_Spotify}
                  alt='Help Spotify'
                />
              </Grid>
              <Grid item xs={6}>
                <Pinterest htmlColor='#ffffff' />
                <br />
                <RulesBlock
                  items={t('Components.Profile.Help.Pinterest', {
                    returnObjects: true,
                  })}
                />
                <Box
                  component='img'
                  sx={{
                    width: '100%',
                    boxShadow: '10px 10px 10px #1e1e1e',
                  }}
                  src={Help_Pinterest}
                  alt='Help Pinterest'
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
}
