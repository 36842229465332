import { Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { Dialog } from 'components/ui';
import { HouseType } from 'types';
import { HouseList } from 'pages/PlayerCharacters/PlayerCharacterCompontents/HouseList';

type Props = {
  open: boolean;
  onClose: () => void;
  onRowClick: (elem) => void;
};

export function InactiveDialog(props: Readonly<Props>) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t('Generic.InactiveLong')}
      content={
        <Box className='content' sx={{ marginTop: '50px' }}>
          <Grid container spacing={2}>
            {['GRYFFINDOR', 'RAVENCLAW', 'HUFFLEPUFF', 'SLYTHERIN'].map(
              (house: HouseType) => (
                <Grid item xs={6} key={house}>
                  <HouseList
                    house={house}
                    onRowClick={props.onRowClick}
                    sx={{
                      paddingLeft: '20px',
                      maxWidth: '700px',
                      minWidth: '10px',
                    }}
                    inactive
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
      }
    />
  );
}
