import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  onClick: (event) => void;
  icon: ReactNode;
  title: string;
};

export default function AdminPanel(props: Readonly<Props>) {
  return (
    <Card
      sx={{
        maxWidth: 200,
        maxHeight: 200,
        margin: '10px 20px 0 0',
        '& .MuiCardActionArea-root:hover': {
          backgroundColor: '#0000002a',
        },
      }}
      onClick={props.onClick}
    >
      <CardActionArea>
        <CardContent
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
              }}
            >
              {props.icon}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
              }}
            >
              <Typography variant='body2' color='text.secondary'>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
