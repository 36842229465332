import { ReactNode } from 'react';
import { t } from 'i18next';

import { Characters } from '../Characters';
import { PlayerCharacterCard } from './PlayerCharacterCompontents/PlayerCharacterCard';

export function PlayerCharacters(): ReactNode {
  return (
    <Characters
      title={t('Components.PlayerCharacters.Title')}
      dialogContent={<PlayerCharacterCard readonly />}
    />
  );
}
