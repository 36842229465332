import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { CustomLink } from 'types';
import { RulesListItemLink } from '../RulesList/RulesListItemLink';

type Props = {
  items: CustomLink[];
};

export function RulesBlockLinks(props: Readonly<Props>): ReactNode {
  return (
    <Box>
      {props.items.map((item) => (
        <RulesListItemLink key={item.title} item={item} />
      ))}
    </Box>
  );
}
