import { Box } from '@mui/system';
import { Headline } from 'components/ui';

import { t } from 'i18next';
import { ClubDataGrid } from './ClubComponents/ClubDataGrid';

export function Clubs() {
  return (
    <Box sx={{ width: '90vw' }}>
      <Headline title={t('Components.Admin.Clubs.Clubs')} />

      <ClubDataGrid />
    </Box>
  );
}
