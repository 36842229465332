import {
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';

import { CHARACTERS_URL, EMPTY, HTTP_METHOD, PEOPLE_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { useEffect, useMemo, useState } from 'react';
import { CharacterRevision, Character } from 'types';
import { Delete } from '@mui/icons-material';
import { CharacterContext } from 'contexts';
import AdminViewDialog from './DialogComponents/AdminViewDialog';
import { DeleteDialog, ThestralDataGrid } from 'components/ui';
import ApprovalStatus from 'pages/Approvals/ApprovalComponents/ApprovalStatus';

export default function AdminPlayerCharacterGrid() {
  const { showSnackbar } = useSnackbar();
  const fetchFromBackend = useFetchFromBackend();

  const [loading, setLoading] = useState<boolean>(true);
  const { data, triggerRefetch } =
    useFetch<CharacterRevision[]>(CHARACTERS_URL);
  const [characters, setCharacters] = useState<Character[]>();
  const [char, setChar] = useState<Character>();
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);

  const [deleteOpen, setDeleteOpen] = useState<boolean>();
  const [viewOpen, setViewOpen] = useState<boolean>(false);

  const theme = useTheme();
  const [colVisModel, setColVisModel] = useState({});
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setColVisModel({
      playerName: !isSm,
    });
  }, [isSm]);

  useEffect(() => {
    if (data) {
      const chars = data.reduce((acc, item) => {
        acc.push(item?.playerCharacter);
        return acc;
      }, []);
      setCharacters(chars);
      setLoading(false);
    }
  }, [data]);

  const onDelete = (event, character: Character) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setChar(character);
  };

  const onRowClick = (elem) => {
    setChar(elem?.row);
    setViewOpen(true);
  };

  const onShow = (event, character: Character) => {
    setLoading(true);
    event.stopPropagation();

    const body = JSON.stringify({ active: !character?.active });
    fetchFromBackend(`${PEOPLE_URL}/${character?.id}/active`, {
      body,
      method: HTTP_METHOD.PATCH,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          triggerRefetch();
        }

        setLoading(false);
        showSnackbar(
          `${t('Components.Admin.Character.AciveSCChange')} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <Box>
      <ThestralDataGrid
        rows={characters || EMPTY}
        columnVisibilityModel={colVisModel}
        columns={[
          {
            field: 'name',
            headerName: t('Generic.Name'),
            sortable: true,
            flex: 2,
          },
          {
            field: 'playerName',
            headerName: t('Generic.OtName'),
            sortable: true,
            flex: 1,
          },
          {
            field: 'ageGroup',
            headerName: isSm
              ? t('Generic.AgeGroupShort')
              : t('Generic.AgeGroup'),
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            valueGetter: (_, row) => {
              const ageGroup = t(`Enum.AgeGroup.${row.ageGroup}`);
              return !row.ageGroup ? '' : isSm ? ageGroup[0] : ageGroup;
            },
          },
          {
            field: 'house',
            headerName: t('Components.Characters.House'),
            sortable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            valueGetter: (_, row) => {
              const house = t(`Enum.House.${row.house}`);
              return !row.house ? '' : isSm ? house[0] : house;
            },
          },
          {
            field: 'actions',
            headerName: 'Aktionen',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => {
              return loading ? (
                <></>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <ApprovalStatus
                    state={params.row?.active}
                    onClick={(event) => onShow(event, params.row)}
                  />
                  <IconButton onClick={(event) => onDelete(event, params.row)}>
                    <Tooltip title={t('Generic.DeleteUser')}>
                      <Delete color='primary' />
                    </Tooltip>
                  </IconButton>
                </Box>
              );
            },
          },
        ]}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        onRowClick={onRowClick}
      />

      {char && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${CHARACTERS_URL}/${char?.id}`}
          i18nBase='Components.Admin.Character'
        />
      )}

      <CharacterContext.Provider value={charContextValue}>
        {char && (
          <AdminViewDialog
            open={viewOpen}
            onClose={() => setViewOpen(false)}
            character={char}
          />
        )}
      </CharacterContext.Provider>
    </Box>
  );
}
