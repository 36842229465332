import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';

import { useState } from 'react';
import AdminPlayerCharacterGrid from './AdminPlayerCharacterGrid';
import AdminNonPlayerCharacterGrid from './AdminNonPlayerCharacterGrid';

export default function AdminCharacters() {
  const [value, setValue] = useState<string>('pc');

  const handleTab = (event, value) => {
    setValue(value);
  };

  return (
    <Box
      className='content'
      sx={{ margin: '75px auto 0 auto', maxWidth: '900px' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleTab}
                centered
                sx={{
                  '& .MuiTab-root': {
                    color: '#ffffff',
                  },
                  '& .Mui-selected': {
                    color: 'primary.main',
                  },
                }}
              >
                <Tab label='SCs' value='pc' />
                <Tab label='NSCs' value='npc' />
              </TabList>

              <TabPanel value='pc'>
                <AdminPlayerCharacterGrid />
              </TabPanel>
              <TabPanel value='npc'>
                <AdminNonPlayerCharacterGrid />
              </TabPanel>
            </Box>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}
