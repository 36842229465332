import { t } from 'i18next';
import { ChangeEvent } from 'react';

import { TextField } from 'components/ui';

type Props = {
  contact: string;
  callback: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export default function ContactPerson(props: Readonly<Props>) {
  return (
    <TextField
      defaultValue={props.contact}
      label={t('Generic.ContactPerson')}
      onChange={props.callback}
    />
  );
}
